
import { defineComponent } from 'vue'
import BaseRoundedIconButton from '@/components/Button/BaseRoundedIconButton.vue'

export default defineComponent({
  components: {
    BaseRoundedIconButton
  },
  setup() {
    return {}
  }
})
