import { ref, computed, markRaw } from 'vue'

// Cells
import SwitchName from '../components/Grid/Cell/TopologyPage/SwitchName.vue'
import SwitchNameHeader from '../components/Grid/Cell/TopologyPage/SwitchNameHeader.vue'
import Operation from '../components/Grid/Cell/TopologyPage/Operation.vue'
import OperationHeader from '../components/Grid/Cell/TopologyPage/OperationHeader.vue'
import SwitchIpv4 from '../components/Grid/Cell/TopologyPage/SwitchIpv4.vue'

// 拓墣圖列表中使用 Kendo Grid
const useGridInHost = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)

  // Header 欄位
  const columns = ref([
    {
      field: 'Site',
      title: '區域',
      width: '133px'
    },
    {
      field: 'Operation',
      title: '操作',
      width: '55px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader)
    },
    {
      field: 'SwitchName',
      title: 'Switch 名稱',
      width: 'auto',
      cell: markRaw(SwitchName),
      headerCell: markRaw(SwitchNameHeader)
    },
    {
      field: 'SwitchIpv4',
      title: 'Switch IP',
      width: '166px',
      cell: markRaw(SwitchIpv4)
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    return dataItemsWithPage
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const site = ['Taipei Office', 'San Jose Office', 'Kevin Office']
    const switchName = [
      ['C3850-24T for 3F', 'DES-3828'],
      [
        'C6513-E',
        'C2960+24TCS',
        'GO-SW-24GE',
        'DGS-105',
        'HP 1810-24G Switch',
        'HP 1410-16G',
        '3CBLSG48'
      ]
    ]
    const switchIpv4 = [
      ['192.168.67.254', '192.168.67.232'],
      [
        '192.168.86.254',
        '192.168.86.231',
        '192.168.86.232',
        '192.168.86.233',
        '192.168.86.234',
        '192.168.86.235',
        '192.168.86.236',
        '192.168.86.237',
        '192.168.2.3'
      ]
    ]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        Site: site[Math.floor(Math.random() * site.length)],
        Operation: { Active: false, TopologyData: {} },
        SwitchName: switchName[Math.floor(Math.random() * switchName.length)],
        SwitchIpv4: switchIpv4[Math.floor(Math.random() * switchIpv4.length)]
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    createRandomData,
    pageChangeHandler
  }
}

export default useGridInHost
