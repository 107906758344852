
import { defineComponent, ref, onMounted, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import usGridInTopology from '@/composable/useGridInTopology'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import BaseRoundedIconButton from '@/components/Button/BaseRoundedIconButton.vue'
import DropdownList from '@/components/Dropdown/DropdownList.vue'
import useGoJsInTopology from '@/composable/useGoJsInTopology'
import Scale from '@/components/Topology/Scale.vue'
import ZoomSlider from '@/components/Topology/ZoomSlider.vue'
import BaseButtonGroup from '@/components/Button/BaseButtonGroup.vue'
import BaseButtonGroupBtn from '@/components/Button/BaseButtonGroupBtn.vue'

export default defineComponent({
  components: {
    FilterWrapper,
    FilterTextInput,
    GridWrapper,
    Grid,
    GridPageInfo,
    BaseRoundedIconButton,
    DropdownList,
    Scale,
    ZoomSlider,
    BaseButtonGroup,
    BaseButtonGroupBtn
  },
  setup() {
    // 列表區開合相關：
    const isListCollapse = ref(false)
    const toggleList = () => (isListCollapse.value = !isListCollapse.value)

    // 查詢拓墣圖列表相關：
    // 文字輸入
    const searchListInput = ref('')
    // 提交
    const searchInList = () => {
      console.log('searchListInput: ' + searchListInput.value)
    }

    // Grid 相關：
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = usGridInTopology()

    const route = useRoute()
    const router = useRouter()

    // 切換拓墣圖相關：
    // 目前 active 拓墣圖 Id
    const currentActiveId = ref(-1)
    const setCurrentActiveId = id => (currentActiveId.value = id)
    // 目前 active 區域名稱
    const currentSiteName = computed(
      () => result.value.find(item => item.Id == currentActiveId.value).Site
    )
    //  拓墣圖選單：選項
    const dropdownItems = ref(['', '對外流量圖'])

    // 操作鈕已點擊，切換目前 active 拓墣圖
    const toggleActiveSite = targetId => {
      // 目前已經 active，跳出
      if (currentActiveId.value == targetId) return
      // 切換 active
      result.value.map(dataItem => {
        dataItem.Operation.Active = dataItem.Id == targetId ? true : false
        return dataItem
      })
      // 更新 currentActiveId
      setCurrentActiveId(targetId)
      console.log('toggleActiveSite, Id:', targetId)
    }

    // 監聽 currentActiveId，執行各種 side effect
    watch(currentActiveId, currentActiveId => {
      if (currentActiveId < 0) return
      // 1. 更新 route.meta.breadcrumb
      route.meta.breadcrumb[1].text = `區域 [${currentSiteName.value}]`
      // 2. 更新 queryString
      router.replace({ query: { siteId: currentActiveId } })
      // 3. 更新拓墣圖下拉選單，並且會自動 force update (因為 component 綁定 :key="currentActiveId")
      dropdownItems.value[0] = currentSiteName.value
      // 4. FIXME: 更新拓墣圖
    })

    // 移除所有 active state
    const removeAllActiveSite = () => {
      result.value.forEach(item => {
        item.Operation.Active = false
      })
    }
    // 設置初始 active state
    const initActiveSite = () => {
      // 手動設置第一個 result 資料的拓墣圖為 active state
      result.value[0].Operation.Active = true
      setCurrentActiveId(result.value[0].Id)
    }

    onMounted(() => {
      dataItems.value = createRandomData(40) as never[]
      initActiveSite()
    })

    const onPageChange = e => {
      // 換頁前：移除 active state
      removeAllActiveSite()
      // 執行換頁：origin page handler
      pageChangeHandler(e)
      // 換頁後：設置初始 active state
      initActiveSite()
    }

    // 查詢拓墣圖相關：
    // 文字輸入
    const searchTopologyInput = ref('')
    // 提交
    const searchInTopology = () => {
      console.log('searchTopologyInput: ' + searchTopologyInput.value)
    }

    // GOJS 相關
    const { myDiagram, myOverviewDiv, init } = useGoJsInTopology()

    onMounted(() => {
      init(myDiagram, myOverviewDiv)
    })

    // 群組按鈕
    const btnGroupUnit = ref([
      { id: 0, name: '百分比', value: 'percentage', active: true },
      { id: 1, name: '絕對值', value: 'default', active: false }
    ])
    const toggleBtnGroupUnit = id => {
      btnGroupUnit.value.forEach(btn => {
        if (btn.id == id) {
          btn.active = true
        } else {
          btn.active = false
        }
      })
    }

    return {
      // 列表區開合相關
      isListCollapse,
      toggleList,
      // 查詢拓墣圖列表相關：文字輸入、提交
      searchListInput,
      searchInList,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      onPageChange,
      // 拓墣圖相關
      toggleActiveSite,
      dropdownItems,
      currentActiveId,
      // 查詢拓墣圖相關：文字輸入、提交
      searchTopologyInput,
      searchInTopology,
      // GOJS 相關
      myDiagram,
      myOverviewDiv,
      // 拓墣圖群組按鈕
      btnGroupUnit,
      toggleBtnGroupUnit
    }
  }
})
