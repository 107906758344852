<template>
  <td class="operation-cell" :data-id="dataItem.Id">
    <KendoTooltip
      id="tooltip"
      title="點擊查看區域拓墣圖與對外流量圖"
      :position="'right'"
    >
      <div
        class="operation-btn"
        :class="{ 'is-active': dataItem.Operation.Active }"
        @click="$emit('toggleActiveSite', dataItem.Id)"
      >
        <SvgIcon icon="topology" />
      </div>
    </KendoTooltip>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  emits: ['toggleActiveSite'], // 操作鈕點擊，切換目前 active 拓墣圖
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped>
.operation-cell {
  text-align: center;
}
</style>
