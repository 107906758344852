import go from 'gojs'
import { ref } from 'vue'
import icon from '@/assets/images/topolopy-icons/switch.svg'

const useGoJsInTopology = () => {
  const myDiagram = ref('')
  const myOverviewDiv = ref('')

  const nodeDataArray = [{ key: 'C6513-E' }, { key: 'GO-SW-24GE' }, { key: 'GO-SW-25GE' }]
  const linkDataArray = [
      { from: 'C6513-E', to: 'GO-SW-24GE', color: "#A1EE88" },
      { from: 'C6513-E', to: 'GO-SW-25GE', color: "#EF443A" }
  ]

  const init = (diagramRef, overviewRef) => {
    const GS = go.GraphObject.make // for conciseness in defining templates
    const myDiagram = GS(go.Diagram, diagramRef.value)

    myDiagram.initialDocumentSpot = go.Spot.Center
    myDiagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray)

    myDiagram.nodeTemplate = GS(
      go.Node, "Spot",
        GS(go.Shape, "RoundedRectangle", { width: 200, height: 60, fill: "transparent", stroke: "transparent" }),
        GS(go.Shape, "RoundedRectangle", { width: 200, height: 85, fill: "#9B9FA8", parameter1: 10, alignment: go.Spot.Top}),
        
        GS(go.Panel, go.Panel.Horizontal,
          { alignment: go.Spot.Top},
          GS(go.Picture, { source: icon, width: 40, height: 40 , margin: new go.Margin(5, 15 ,20 ,0), background: "#232731"}),
          GS(go.Panel, go.Panel.Table, { defaultAlignment: go.Spot.Left },
            GS(go.TextBlock, "DLink", { row: 0, column:0 , stroke: "#171A1D", font: "600 12px Arial", margin: new go.Margin(5, 0 ,0 ,0) }),
            GS(go.TextBlock, "192.168.86.232", { row: 1, column:0 ,stroke: "#171A1D", font: "600 12px Arial", margin: new go.Margin(5, 0 ,0 ,0) }),
            GS(go.TextBlock, "上線 MAC: 0", { row: 2, column:0 ,stroke: "#393D46", margin: new go.Margin(20, 0 ,0 ,0) , font: "600 11px Arial"}),
          )
        ),
        GS(go.TextBlock, { stroke: "#fff", alignment: go.Spot.MiddleBottom, font: '500 14px Arial' } , new go.Binding("text", "key"))
    )

    myDiagram.linkTemplate = GS(
      go.Link,
      { 
        curve: go.Link.Bezier,
        layerName: 'Background'
      },
      GS(go.Shape, {strokeWidth: 5}, new go.Binding("stroke", "color") ),
      GS(go.Panel, "Auto", { 
        segmentIndex: 0,
        segmentFraction: 0.8 },
        GS(go.Shape, "RoundedRectangle", { fill: "#393D46", width: 70, height: 30, parameter1: 5}),
        GS(go.TextBlock, "Fa0/16", { stroke: '#fff' }),
      ),
      GS(go.Panel, "Auto", { 
        segmentIndex: 2,
        segmentFraction: 0.2 },
        GS(go.Shape, "RoundedRectangle", { fill: "#393D46", width: 70, height: 30, parameter1: 5}),
        GS(go.TextBlock, "Fa0/24", { stroke: '#fff' }),
      ),
    )

    // Overview
    const myOverview = GS(go.Overview, overviewRef.value, {
      observed: myDiagram,
      contentAlignment: go.Spot.Center
    })
    myOverview.box.elt(0).stroke = '#797E89'
  }

  return {
    myDiagram,
    myOverviewDiv,
    init
  }
}

export default useGoJsInTopology
