<template>
  <div class="scale-wrapper">
      <div class="scale">
          <div 
            class="scale-item"
            v-for="(color, index) in colors" 
            :key="index"
            :style="{ backgroundColor: color }">
        </div>
      </div>
      <div class="graduation-content">
          <span class="graduation" v-for="(Graduation, index) in Graduations" :key="index">
            {{ Graduation }}
            </span>
      </div>
  </div>
  
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const colors = [
      '#A1EE88',
      '#A9D670',
      '#C1BE60',
      '#E3AA5B',
      '#F28F52',
      '#EF6843',
      '#EF443A',
      '#EC3737',
      '#C13142',
      '#A63254',
      '#8A316E',
      '#6C3790',
    ]

    const Graduations = [
      '0m',
      '20m',
      '40m',
      '60m',
      '80m',
      '100m',
      '200m',
      '400m',
      '600m',
      '800m',
      '1G',
      '5G',
      '10G',
    ]

    return { colors, Graduations }
  },
})
</script>

<style scoped>
    
</style>
