<template>
  <div class="zoom-slider-wrapper">
    <BaseRoundedIconButton class="handling-btn" :icon="'reload'" />
    <div class="zoom-slider">
      <button class="zoom-btn zoom-in"></button>
      <div class="slide"></div>
      <button class="zoom-btn zoom-out"></button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseRoundedIconButton from '@/components/Button/BaseRoundedIconButton.vue'

export default defineComponent({
  components: {
    BaseRoundedIconButton
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
