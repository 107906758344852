<template>
  <td class="switch-name-cell" :data-id="dataItem.Id">
    <div v-for="(name, idx) in dataItem.SwitchName" :key="idx">
      {{ name }}
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped>
.switch-name-cell {
  padding-left: 29px;
}
</style>
